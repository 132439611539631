// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'nl',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '052268b3d5f6d166caff2a1a0f25473caea15546',
    releaseTagRef: 'af1c79b',
    releaseTimestamp: '1726558010',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: 'clicks.burgerking.nl',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [{'_key':'c77af30449de','_type':'iDealBanks','name':'Test Issuer','id':'1121'},{'name':'Test Issuer 2','id':'1151','_key':'fea7a6920d06','_type':'iDealBanks'}],
      }
    },
    apiKeys: {
      amplitude: 'a5f9f13229f538562aa7fd4dab773184',
      branch: '',
      braze: '0241822b-51ab-408f-a22b-c551441efaba',
      brazeCluster: 'sdk.fra-01.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyAzV2be2f9MKiFnGWTLVsv3hs20ywbmOzk',
      googleMapsAndroid: '',
      googleMapsIOS: '',
      googleMapsWeb: '',
      tomtom: '',
      launchDarkly: '644687504b9d9412dd2dc5d8',
      launchDarklyMobileKey: 'mob-eac31e2f-c2be-49fe-96ce-bd8a1236e853',
      mParticle: 'eu1-388098d94d7a524abeea87f77c1534f7',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
    },
    apple: {
      merchantId: '',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: 'com.bk.nl.sociallogin',
        redirectURI: 'https://bk-nl-8e042.firebaseapp.com/__/auth/handler',
      },
    },
    branch: {
      domains: ['burgerkingnl.test-app.link','burgerkingnl-alternate.test-app.link','burgerkingnl.app.link','burgerkingnl-alternate.app.link']
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '62ev1gv53cp9bckrt5gdinrqmj',
      userPoolId: 'eu-central-1_dwCOsnxKY',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '650791251592-vrk03p4hmib5uemvr9u49ipved5n8cpu.apps.googleusercontent.com',
        redirectURI: 'https://bk-nl-8e042.firebaseapp.com/__/auth/handler',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-W8HKW99',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://dashboard-01.braze.eu',
      firstData: 'https://api-int.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'customCookieBanner',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: '',
          consentCategoryMParticle: '',
          consentCategoryDdRum: '',
          globalConsentState: '',
          scriptUrl: '',
          scriptId: '',
        },
      },
    }
  };